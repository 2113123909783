/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-11-12",
    versionChannel: "nightly",
    buildDate: "2024-11-12T00:06:21.773Z",
    commitHash: "fddd7609ca4b8e54ee2a66952826eef5e47d671e",
};
